import { Button, Section } from '@guess-the-rose/web-shared'
import { NextSeo } from 'next-seo'
import Link from 'next/link'
import React from 'react'

import { Layout } from '../components'
import { HowItWorksCallout } from '../components/Callouts'
import { Countdown } from '../components/Countdown'
import { LatestFromBlog } from '../components/LatestFromBlog'
import { MyEntries } from '../components/MyEntries'
import { WithAuthProps, WithMetaProps, withAuth, withMeta } from '../hocs'
import { isBeforeSeasonStart, useFirebaseAnalytics } from '../lib'

export default withAuth(
  withMeta<WithMetaProps & WithAuthProps>(({ meta }) => {
    const analytics = useFirebaseAnalytics()

    return (
      <Layout>
        <NextSeo title="Home | Guess the Rose" />

        <HowItWorksCallout />
        <MyEntries />
        {isBeforeSeasonStart(meta.currentSeasonStatus) && (
          <Section
            innerContainerProps={{
              display: ['block', null, 'flex'],
              justifyContent: [null, null, 'center'],
            }}
          >
            <Link href="/join-a-group">
              <Button
                display="flex"
                mb={['4', null, 0]}
                mx={['auto', null, '2']}
                onClick={() =>
                  analytics?.logEvent('joinAGroupClickedDashboard')
                }
                variant="outline"
              >
                Join a Group
              </Button>
            </Link>
            <Link href="/groups/create">
              <Button
                as="a"
                display="flex"
                mx={['auto', null, '2']}
                onClick={() =>
                  analytics?.logEvent('createAGroupClickedDashboard')
                }
                variant="solid"
              >
                Create a Group
              </Button>
            </Link>
          </Section>
        )}
        <Countdown />
        <LatestFromBlog />
      </Layout>
    )
  }),
)
